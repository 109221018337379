import Box from "common/components/Box";
import Button from "common/components/Button";
import Drawer from "common/components/Drawer";
import HamburgMenu from "common/components/HamburgMenu";
import NavbarWrapper from "common/components/Navbar";
import ScrollSpyMenu from "common/components/ScrollSpyMenu";
import Container from "common/components/UI/Container";
import { DrawerProvider, DrawerContext } from "common/contexts/DrawerContext";
import { graphql, Link, useStaticQuery } from "gatsby";
import React, { useContext } from "react";
import Logo from "common/components/UIElements/Logo";
import { useTranslation } from "react-i18next";
import Sticky from "react-stickynode";
import { NavBarHomeWrapper } from "./navbar.style";

import TextLogoWhite from "common/assets/image/text_logo_white.svg";
import TextLogoBlack from "common/assets/image/text_logo_black.svg";
import { useState } from "react";
import { useEffect } from "react";

const Navbar = ({ routePath }) => {
  const isHomePage = routePath === "/";
  const activeClassName = isHomePage ? "sticky-nav-active" : "";
  const className = isHomePage ? "" : "sticky-nav-active";
  const [showElement, setShowElement] = useState(false);
  useEffect(() => {
    setTimeout(function () {
      setShowElement(true);
    }, 200);
  }, []);
  return (
    <NavBarHomeWrapper>
      {showElement ? (
        <Sticky
          top={0}
          innerZ={9999}
          activeClass={activeClassName}
          className={className}
        >
          <DrawerProvider>
            <NavBarContent routePath={routePath} />
          </DrawerProvider>
        </Sticky>
      ) : (
        <Sticky
          top={0}
          innerZ={9999}
        >
          <DrawerProvider>
            <NavBarContent routePath={routePath} />
          </DrawerProvider>
        </Sticky>
      )}
    </NavBarHomeWrapper>
  );
};

const NavBarContent = ({ routePath }) => {
  const { state, dispatch } = useContext(DrawerContext);
  console.log("routePath", routePath);

  const { t } = useTranslation();

  // Toggle drawer
  const toggleHandler = () => {
    dispatch({
      type: "TOGGLE",
    });
  };

  const data = useStaticQuery(graphql`
    query {
      allVisuellaJson {
        nodes {
          menu_items {
            key
          }
        }
      }
    }
  `);

  let menu_items = [];

  menu_items = data.allVisuellaJson.nodes[0].menu_items.map((item) => {
    return {
      ...{
        key: item.key,
        label: t(`navbar.${item.key}`),
      },
    };
  });

  return (
    <NavbarWrapper
      {...{
        className: "navbar",
        minHeight: "70px",
        display: "block",
      }}
    >
      <Container width="100%">
        <Box
          {...{
            flexBox: true,
            alignItems: "center",
            width: "100%",
          }}
        >
          <Box className="logo-container">
            <Logo
              href="/"
              logoSrc={TextLogoWhite}
              title="Logo White"
              logoStyle={{
                maxWidth: ["126px", "126px"],
              }}
              className="logo-white"
            />
            <Logo
              href="/"
              logoSrc={TextLogoBlack}
              title="Logo Black"
              logoStyle={{
                maxWidth: ["126px", "126px"],
              }}
              className="logo-black"
            />
          </Box>
          <Box
            {...{
              flexBox: true,
              alignItems: "center",
            }}
            className="mainMenuWrapper"
          >
            <ScrollSpyMenu
              key={"desktop_menu"}
              className="main_menu"
              menuItems={menu_items}
              offset={-70}
              routePath={routePath}
            />
            <div className="navbar-buttons">
              <Link to="#footer" className="navbar_button navbar_button_two">
                <Button title={t("common.contactUs")} />
              </Link>
            </div>
            <Drawer
              width="420px"
              placement="right"
              drawerHandler={<HamburgMenu />}
              open={state.isOpen}
              toggleHandler={toggleHandler}
            >
              <ScrollSpyMenu
                key={"mobile_menu"}
                className="mobile_menu"
                menuItems={menu_items}
                drawerClose={true}
                offset={-100}
                routePath={routePath}
              />
            </Drawer>
          </Box>
        </Box>
      </Container>
    </NavbarWrapper>
  );
};

export default Navbar;
