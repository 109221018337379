import React, { useState, useEffect } from "react";
import { FaArrowUp } from "react-icons/fa";
import { ScrollButton } from "./scroll_to_top.style";

const ScrollToTop = () => {
  const [showButton, setShowButton] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 300) {
        setShowButton(true);
      } else {
        setShowButton(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <ScrollButton onClick={scrollToTop} show={showButton}>
      <FaArrowUp size={18} />
    </ScrollButton>
  );
};

export default ScrollToTop;
