import React, { useRef, useState } from "react";
import {
  Box,
  ListItemButton,
  ListItemText,
  MenuItem,
  Popover,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { useSettings } from "../../contexts/SettingsContext";

import fr from "common/assets/image/langs/fr_flag.svg";
import en from "common/assets/image/langs/uk_flag.svg";

const languageOptions = {
  fr: {
    icon: fr,
    label: "FR",
  },
  en: {
    icon: en,
    label: "EN",
  },
};

const LanguagePopover = (props) => {
  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);

  const { settings, saveSettings } = useSettings();

  const { i18n } = useTranslation();

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleLanguageChange = (newLanguage) => {
    i18n.changeLanguage(newLanguage);
    saveSettings({
      direction: "ltr",
      language: newLanguage,
      pinSidebar: true,
      theme: "light",
    });
    setOpen(false);
  };

  const selectedOption = languageOptions[settings.language];

  return (
    <>
      <ListItemButton
        onClick={handleOpen}
        ref={anchorRef}
        className={"language-menu"}
        disableRipple
        style={{
          padding: "2px",
          paddingTop: "0px",
          paddingBottom: "0px",
          paddingLeft: "0px",
          paddingRight: "0px",
          "&:hover": {
            backgroundColor: "transparent",
          },
          width: "fit-content",
        }}
      >
        <Box
          className={"language-icon"}
          style={{
            display: "flex",
            height: "11px",
            width: "20px",
            "& img": {
              width: "100%",
            },
          }}
        >
          <img
            alt={selectedOption.label}
            src={selectedOption.icon}
            style={{ width: "20px", height: "auto" }}
          />
        </Box>
        <ListItemText
          primary={
            <Typography
              variant="subtitle2"
              className={"language-text"}
              style={{
                display: "flex",
                alignItems: "center",
                fontSize: "14px",
              }}
            >
              {selectedOption.label}
            </Typography>
          }
        />
      </ListItemButton>
      <Popover
        anchorEl={anchorRef.current}
        anchorOrigin={{
          horizontal: "center",
          vertical: "bottom",
        }}
        transformOrigin={{
          horizontal: "center",
          vertical: "top",
        }}
        keepMounted
        onClose={handleClose}
        open={open}
        style={{ zIndex: 9999 }}
      >
        {Object.keys(languageOptions).map((option) => (
          <MenuItem
            onClick={() => handleLanguageChange(option)}
            key={option}
            sx={{
              padding: "5px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                height: "11px",
                width: "20px",
                "& img": {
                  width: "100%",
                  height: "auto",
                },
              }}
            >
              <img
                alt={languageOptions[option].label}
                src={languageOptions[option].icon}
                style={{ width: "100%", height: "auto" }}
              />
            </Box>
            <ListItemText
              primary={
                <Typography
                  color="textPrimary"
                  variant="subtitle2"
                  style={{ display: "flex", alignItems: "center" }}
                >
                  {languageOptions[option].label}
                </Typography>
              }
            />
          </MenuItem>
        ))}
      </Popover>
    </>
  );
};

export default LanguagePopover;
