import React from "react";
import PropTypes from "prop-types";
import { useStaticQuery, graphql } from "gatsby";
import Box from "common/components/Box";
import Text from "common/components/Text";
import Heading from "common/components/Heading";
import Logo from "common/components/UIElements/Logo";
import Container from "common/components/UI/Container";
import { FooterWrapper, List, ListItem, Nav } from "./footer.style";
import LogoImage from "common/assets/image/text_logo_black.svg";
import { useTranslation } from "react-i18next";
import Image from "common/components/Image";
import Link from "common/components/Link";

const Footer = ({
  parentCol,
  colOne,
  colTwo,
  colThree,
  colFour,
  rowOne,
  rowTwo,
  titleStyle,
  logoStyle,
  textStyle,
  copyright,
}) => {
  const data = useStaticQuery(graphql`
    query {
      allVisuellaJson {
        nodes {
          footer {
            aboutUs {
              key
              link
            }
            ourInformation {
              key
              link
            }
            social {
              title
              link
              icon {
                publicURL
              }
            }
            contact {
              title
              link
            }
          }
        }
      }
    }
  `);

  const { aboutUs, ourInformation, social, contact } =
    data.allVisuellaJson.nodes[0].footer;

  const { t } = useTranslation();

  return (
    <FooterWrapper id="footer">
      <Container>
        <Box className="col" {...parentCol}>
          <Box className="row" {...rowOne}>
            <Box className="col" {...colOne}>
              <Heading content={t(`footer.aboutUs`)} {...titleStyle} />
              <List>
                {aboutUs.map((item) => (
                  <ListItem key={item.key}> {/* Ensure key is unique */}
                    <a href={item.link} className="ListItem" key={item.key}>
                      {t(`footer.${item.key}`)}
                    </a>
                  </ListItem>
                ))}
              </List>
            </Box>

            <Box className="col" {...colTwo}>
              <Heading content={t(`footer.ourInfo`)} {...titleStyle} />
              <List>
                {ourInformation.map((item) => (
                  <ListItem key={item.key}> {/* Ensure key is unique */}
                    <a href={item.link} className="ListItem">
                      {t(`footer.${item.key}`)}
                    </a>
                  </ListItem>
                ))}
              </List>
            </Box>

            <Box className="col" {...colThree}>
              <Heading content={t(`footer.connect`)} {...titleStyle} />
              <Nav>
                {social.map((item) => (
                  <Link key={item.title} href={item.link}> {/* Ensure key is unique */}
                    <Image
                      src={item.icon.publicURL}
                      alt={item.title}
                      width={18}
                    />
                    {item.title}
                  </Link>
                ))}
              </Nav>
            </Box>

            <Box className="col" {...colFour}>
              <Heading content={t(`common.contactUs`)} {...titleStyle} />
              <Nav>
                <>
                  {contact.map((item) => (
                    <ListItem key={item.title}> {/* Ensure key is unique */}
                      <a href={item.link} className="ListItem">
                        {item.title}
                      </a>
                    </ListItem>
                  ))}
                  <iframe
                    src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d12880.00535152448!2d5.4101585!3d36.1908493!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x12f315fcd8396f11%3A0xad8275ab060bed80!2sBusiness%20Center%20Park%20mall!5e0!3m2!1sen!2sdz!4v1724494148417!5m2!1sen!2sdz"
                    title="Visuella"
                    style={{
                      width: "400",
                      height: "200",
                      style: "border:0",
                      allowfullscreen: "",
                      loading: "lazy",
                      referrerpolicy: "no-referrer-when-downgrade",
                    }}
                  ></iframe>
                </>
              </Nav>
            </Box>
          </Box>

          <Box {...rowTwo} className="copyrightClass">
            <Logo
              href="/app"
              logoSrc={LogoImage}
              title="App"
              logoStyle={logoStyle}
            />
            <Box {...copyright} className="copyrightText">
              <Text
                content={`copyright © ${new Date().getFullYear()} Visuella.`}
                {...textStyle}
              />
            </Box>
          </Box>
        </Box>
      </Container>
    </FooterWrapper>
  );
};

// Footer style props
Footer.propTypes = {
  parentCol: PropTypes.object,
  col: PropTypes.object,
  colOne: PropTypes.object,
  colTwo: PropTypes.object,
  titleStyle: PropTypes.object,
  textStyle: PropTypes.object,
  logoStyle: PropTypes.object,
};

// Footer default style
Footer.defaultProps = {
  // Footer parentCol default style
  parentCol: {
    flexBox: true,
    flexWrap: "wrap",
    ml: "-15px",
    mr: "-15px",
  },
  // Footer rowOne style
  rowOne: {
    width: ["100%"],
    flexBox: true,
    flexWrap: "wrap",
  },
  // Footer rowTwo style
  rowTwo: {
    mt: [0, "13px"],
    mb: ["0px", 0],
    pl: ["15px", 0],
    pt: ["35px", "55px"],
    pr: ["15px", "15px", 0],
    borderTop: "1px solid",
    borderColor: "rgba(0,0,0,0.102)",
    flexBox: true,
    flexWrap: "wrap",
    width: ["100%"],
  },
  // Footer col default style
  colOne: {
    width: ["100%", "20%"],
    pl: ["15px", "0px"],
    pr: ["15px", "0px"],
    mb: "30px",
  },
  colTwo: {
    width: ["100%", "30%"],
    pl: ["15px", "0px"],
    pr: ["15px", "0px"],
    mb: "30px",
  },
  colThree: {
    width: ["100%", "20%"],
    pl: ["15px", "0px"],
    pr: ["15px", "0px"],
    mb: "30px",
  },
  colFour: {
    width: ["100%", "30%"],
    pl: ["15px", "0px"],
    pr: ["15px", "0px"],
    mb: "30px",
  },
  // widget title default style
  titleStyle: {
    color: "#343d48",
    fontSize: "16px",
    fontWeight: "700",
  },
  // Default logo size
  logoStyle: {
    width: "auto",
    mb: ["15px", 0],
  },
  // widget text default style
  textStyle: {
    color: "#20201d",
    fontSize: "14px",
    mb: "10px",
    mr: "30px",
  },
  copyright: {
    ml: [0, 0, 0, "auto", "auto"],
    color: "#20201d",
    fontSize: "14px",
    mb: "10px",
    mt: "3px",
    fontWeight: "500",
    justifyContent: "center",
    alignItems: "center",
  },
};

export default Footer;
