import styled from "styled-components";
import { themeGet } from "@styled-system/theme-get";

export const NavBarHomeWrapper = styled.footer`
  .logo-container {
    position: relative;
    a {
      transition: all 0.3s ease-in-out 0s;
    }
    .sticky-logo {
      position: absolute;
      left: 0;
      top: 0;
      opacity: 0;
      visibility: hidden;
    }
  }

  .navbar {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    transition: 0.35s ease-in-out;
    padding: 30px 0;
    background-color: transparent;

    @media (min-width: 991px) {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .container {
      @media only screen and (max-width: 1366px) {
        max-width: 1170px;
      }
    }
    .mainMenuWrapper {
      flex: 1 1 100%;
      @media (max-width: 991px) {
        flex: 0 0 auto;
        margin-left: auto;
      }
    }
    .logo-white {
      opacity: 1;
      visibility: visible;
      display: block;
    }
    .logo-black {
      opacity: 0;
      visibility: hidden;
      display: none;
    }
    .main_menu {
      @media (min-width: 991px) {
        align-items: center;
        display: flex;
        flex-grow: 1;
        justify-content: center;
      }
      li {
        display: inline-flex;
        padding-left: 13px;
        padding-right: 13px;
        @media (max-width: 1024px) {
          padding-left: 8px;
          padding-right: 8px;
        }
        &:first-child {
          padding-left: 0;
        }
        &:last-child {
          padding-right: 0;
        }
        &.is-current {
          a {
            color: ${themeGet("colors.white")};
          }
        }
        a {
          color: ${themeGet("colors.white")};
          font-size: 16px;
          line-height: 2.2;
          padding: 5px;
          transition: 0.15s ease-in-out;
          font-weight: bold;
          &:hover {
            color: ${themeGet("colors.primary")};
          }
        }
      }
      @media (max-width: 990px) {
        display: none;
      }
    }

    .language-menu {
      .language-text {
        color: ${themeGet("colors.white")};
      }
    }

    .navbar-buttons {
      margin-left: auto;
      align-items: center;
      display: grid;
      gap: 20px;
      grid-template-columns: repeat(1, 1fr);
      button {
        font-size: 15px;
        line-height: 19px;
        min-height: 45px;
        padding: 0 20px;
        border-radius: 30px;
      }
    }
    .navbar_button_one {
      font-weight: 400;
      background-color: transparent;
      color: ${themeGet("colors.black")};
      padding: 0;
      border: 1px solid ${themeGet("colors.borderColor")};
      .btn-icon {
        margin-right: 9px;
      }
      .btn-text {
        padding: 0;
      }
      @media (max-width: 990px) {
        display: none;
      }
    }
    .navbar_button_two {
      display: inline-flex;
      button {
        background-color: ${themeGet("colors.white")};
        color: ${themeGet("colors.headingColor")};
        font-size: 15px;
        font-weight: bold;
        border-radius: 22.5px;
        transiton: all 500ms ease;
        &:hover {
          background-color: ${themeGet("colors.primary")};
          color: ${themeGet("colors.white")};
        }
      }
      @media (max-width: 990px) {
        display: none;
      }
    }
    .reusecore-drawer__handler {
      @media (min-width: 991px) {
        display: none !important;
      }
      .hamburgMenu__bar {
        > span {
          background-color: ${themeGet("colors.white")};
        }
      }
    }
  }

  .sticky-nav-active {
    .navbar {
      background-color: ${themeGet("colors.white")};
      box-shadow: 0px 3px 8px 0px ${themeGet("colors.navBarShadowColor")};
      padding: 15px 0;
    }
    .logo-white {
      opacity: 0;
      visibility: hidden;
      display: none;
    }
    .logo-black {
      opacity: 1;
      visibility: visible;
      display: block;
    }
    .stricky-logo {
      display: block;
    }
    .main_menu li a {
      color: ${themeGet("colors.headingColor")};
      font-weight: bold;
    }
    .main_menu li:hover a,
    .main_menu li.is-current a {
      color: ${themeGet("colors.linkColor")};
    }
    .main_menu li .language-menu .language-text {
      color: ${themeGet("colors.headingColor")};
    }
    .reusecore-drawer__handler {
      .hamburgMenu__bar {
        > span {
          background-color: ${themeGet("colors.headingColor")};
        }
      }
    }
    .navbar_button_one {
      background-color: ${themeGet("colors.transparent")};
      color: ${themeGet("colors.headingColor")};
    }
    .navbar_button_two button {
      color: ${themeGet("colors.white")};
      background-color: ${themeGet("colors.primary")};
      &:hover {
        color: ${themeGet("colors.white")};
        background-color: ${themeGet("colors.linkColor")};
      }
    }
  }
`;
