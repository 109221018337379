/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it

const React = require("react");
const Seo = require("./src/components/seo").default;
const { Analytics } = require("@vercel/analytics/react");
const { ResetCSS } = require("./src/common/assets/css/style");
const {
  GlobalStyle,
  ContentWrapper,
} = require("./src/containers/visuella/visuella.style");

const SettingsProvider =
  require("./src/common/contexts/SettingsContext").SettingsProvider;
const SettingsConsumer =
  require("./src/common/contexts/SettingsContext").SettingsConsumer;
const { ThemeProvider } = require("styled-components");
const { theme } = require("./src/common/theme/visuella");
const { getDefaultLanguage } = require("./src/i18n");
const {
  default: ScrollToTop,
} = require("./src/containers/visuella/ScrollToTop");
const Navbar = require("./src/containers/visuella/Navbar").default;
const Footer = require("./src/containers/visuella/Footer").default;

// Wraps every page in a component
exports.wrapPageElement = ({ element, props }) => {
  return (
    <>
      <Seo title="Visuella" />
      <Analytics />
      <ResetCSS />
      <GlobalStyle />
      <ContentWrapper>
        <Navbar routePath={props.path} />
        {element}
        <Footer />
        <ScrollToTop />
      </ContentWrapper>
    </>
  );
};

// wrap context and providers
exports.wrapRootElement = ({ element }) => {
  return (
    <SettingsProvider>
      <SettingsConsumer>
        {({ settings }) => (
          <ThemeProvider theme={theme}>{element}</ThemeProvider>
        )}
      </SettingsConsumer>
    </SettingsProvider>
  );
};
