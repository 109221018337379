import { rgba } from 'polished';

const colors = {
  transparent: 'transparent',
  black: '#000000',
  white: '#ffffff',
  headingColor: '#0F2137',
  textColor: rgba('#0F2137', 0.7),
  textColorAlt: '#0F2137',
  textColorLight: rgba('#fff', 0.7),
  labelColor: '#767676',
  inactiveField: '#f2f2f2',
  inactiveButton: '#b7dbdd',
  inactiveIcon: '#EBEBEB',
  primary: '#09033f',
  primaryHover: '#140e89',
  secondary: '#FF9B3E',
  borderColor: rgba('#0F2137', 0.2),
  linkColor: '#1089ff',
  primaryBoxShadow: '0px 8px 20px -6px rgba(42, 162, 117, 0.57)',
  secondaryBoxShadow: '0px 8px 20px -6px rgba(237, 205, 55, 0.5)',
  visuellaPrimary: '#9b4fff',
  visuellaSecondary: '#5ba1f7',
  whiteLowOpacity: rgba('#ffffff', 0.2),
  blackLowOpacity: rgba(0, 0, 0, 0.2),
  greyLowOpacity: rgba(80, 99, 126, 0.1),
  grey: '#343D48',
  spaceGrey: '#e3e3e3',
  lightGrey: '#f4f5f9',
  footerBackgroundColor: 'rgb(246, 249, 252)',
  footerTextColor: rgba(52, 61, 72, 0.8),
  navBarShadowColor: rgba(43, 83, 135, 0.08),
  blueShadowColor: rgba("#5B84C1", 0.1),
  lineColor: '#eae9f2',
  starYellow: '#F6C416',
  starGrey: '#E0E0E0',
};

export default colors;
