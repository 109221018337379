import styled, { createGlobalStyle } from "styled-components";
import { themeGet } from "@styled-system/theme-get";

export const GlobalStyle = createGlobalStyle`
  body{
    font-family: 'Inter', sans-serif;
    font-weight: 400;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: 'Manrope', sans-serif;
    color: ${themeGet("colors.headingColor")};
  }

  input, textarea {
    font-family: 'Inter', sans-serif;
  }

  button {
    font-family: 'Manrope', sans-serif;
  }

  section {
    position: relative;
  }

  .drawer-content-wrapper{
    @media (max-width: 767px) {
      width: 300px!important;
    }
    .drawer-content {
      padding: 60px;    
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      @media (max-width: 767px) {
        padding: 50px 40px 30px 40px;
      }
      .mobile_menu {
        margin-bottom: 40px;
        flex-grow: 1;
        @media (max-width: 767px) {
          margin-bottom: 30px;
        }
        li{
          margin-bottom: 35px;
          @media (max-width: 767px) {
            margin-bottom: 25px;
          }
          a{
            font-size: 18px;
            font-weight: 400;
            color: ${themeGet("colors.grey")};
            position: relative;
            transition: 0.15s ease-in-out;
            @media (max-width: 767px) {
              font-size: 18px;
            }
            &:hover {
              color: ${themeGet("colors.visuellaPrimary")};
            }
            &:before{
              content: '';
              width: 7px;
              height: 7px;
              background: ${themeGet("colors.visuellaPrimary")};
              border-radius: 50%;
              position: absolute;
              top: 50%;
              left: -15px;
              transform: translateY(-50%);
              opacity: 0;
            }
          }
          &.is-current {
            a {
              color: ${themeGet("colors.visuellaPrimary")};
              &:before{
                opacity: 1;
              }
            }
          }
        }
      }
      .navbar_drawer_button button{
        width: 100%;
      }
    }

    .reusecore-drawer__close{
      width: 34px;
      height: 34px;
      position: absolute;
      top: 20px;
      right: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      @media (max-width: 767px) {
        top: 15px;
        right: 15px;
      }
      &:before{
        content: '\f10b';
        font-family: Flaticon;
        font-size: 26px;
        color: ${themeGet("colors.visuellaPrimary")};
        transform: rotate(45deg);
        display: block;
      }
    }
  }
`;

export const ContentWrapper = styled.div`
  width: full;
  overflow: hidden;
`;

export const SectionWrapper = styled.section`
  overflow: hidden;
  padding-bottom: var(--paddingBottom);
  padding-top: var(--paddingTop);
  background-color: var(--backgroundColor);
  .container {
    > header {
      max-width: 400px;
      @media only screen and (max-width: 480px) {
        max-width: 340px;
      }
    }
    .view-all {
      color: ${themeGet("colors.linkColor")};
      font-weight: 700;
      font-size: 15px;
      display: inline-flex;
      padding-top: 40px;
      text-transform: uppercase;
      @media only screen and (max-width: 768px) {
        justify-content: center;
        width: 100%;
      }
      i {
        line-height: 1;
        transform: translateX(2px);
        transition: 0.3s ease 0s;
      }
      &:hover i {
        transform: translateX(5px);
      }
      @media only screen and (min-width: 1024px) and (max-width: 1366px) {
        margin-top: 15px;
      }
      @media only screen and (min-width: 769px) and (max-width: 1024px) {
        margin-top: 0;
      }
      @media only screen and (max-width: 768px) {
        margin-top: 15px;
      }
      @media only screen and (max-width: 480px) {
        margin-top: 10px;
      }
      .view-all-icon {
        padding-left: 5px;
      }
    }
  }
`;

export const SectionHeader = styled.header`
  max-width: 400px;
  width: 100%;
  margin: 0 auto 58px;
  text-align: center;
  @media only screen and (max-width: 991px) {
    margin-bottom: 50px;
  }
  h5 {
    font-size: 14px;
    font-weight: 700;
    line-height: 24px;
    margin-bottom: 12px;
    letter-spacing: 1.5px;
    color: ${themeGet("colors.linkColor")};
    text-transform: uppercase;
    @media only screen and (max-width: 991px) {
      font-size: 13px;
      margin-bottom: 10px;
    }
  }
  h2 {
    font-size: 30px;
    line-height: 36px;
    font-weight: 700;
    color: ${themeGet("colors.headingColor")};
    margin: 0;
    letter-spacing: -1px;
    @media only screen and (max-width: 1366px) {
      font-size: 28px;
      letter-spacing: -0.7px;
    }
    @media only screen and (max-width: 991px) {
      font-size: 26px;
      line-height: 38px;
      letter-spacing: -0.5px;
    }
  }
  p {
    font-size: 16px;
    line-height: 28px;
    color: ${themeGet("colors.black")};
    padding-top: 20px;
    @media only screen and (max-width: 1366px) {
      font-size: 15px;
    }
  }
`;
