import styled from "styled-components";
import { themeGet } from "@styled-system/theme-get";

export const ScrollButton = styled.button`
  position: fixed;
  width: 60px;
  height: 60px;
  bottom: 24px;
  right: 100px;
  background-color: ${themeGet("colors.visuellaPrimary")};
  color: #fff;
  border: none;
  border-radius: 50%;
  padding: 10px;
  cursor: pointer;
  display: ${({ show }) => (show ? "block" : "none")};
  z-index: 1000;
  transition: opacity 0.3s, transform 0.3s;
  box-shadow: 0 3px 1.5px rgba(0, 0, 0, 0.2);
  &:hover {
    background-color: ${themeGet("colors.visuellaSecondary")};
    transform: scale(1.1);
  }
`;
