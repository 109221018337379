import styled from 'styled-components';
import { themeGet } from "@styled-system/theme-get";

export const FooterWrapper = styled.section`
  padding: 80px 0 55px;
  margin-top: 40px;
  background-color: ${themeGet('colors.footerBackgroundColor')};
  @media (max-width: 480px) {
    padding: 60px 0 30px;
  }
  .copyrightClass {
    @media (max-width: 1024px) {
      display: flex;
      flex-direction: column;
        justify-content: center;
      align-items: center;
    }
    .copyrightText {
      @media (max-width: 1100px) {
        padding-top: 10px;
        justify-content: center;
        margin-left: 0;
      }
    }
  }
`;

export const List = styled.ul``;

export const ListItem = styled.li`
  a {
    color: ${themeGet('colors.footerTextColor')};
    font-size: 14px;
    line-height: 36px;
    transition: all 0.2s ease;
    &:hover,
    &:focus {
      outline: 0;
      text-decoration: none;
      color: ${themeGet('colors.grey')};
    }
  }
`;

export const Nav = styled.nav`
  a {
    color: ${themeGet('colors.footerTextColor')};
    display: flex;
    align-items: center;
    font-size: 14px;
    line-height: 36px;
    transition: all 0.2s ease;
    img {
      margin-right: 12px;
    }
    &:hover {
      color: ${themeGet('colors.primary')};
    }
  }
`;